import { render, staticRenderFns } from "./DailyOperationsStatistics.vue?vue&type=template&id=5185895a&scoped=true"
import script from "./DailyOperationsStatistics.vue?vue&type=script&lang=js"
export * from "./DailyOperationsStatistics.vue?vue&type=script&lang=js"
import style0 from "./DailyOperationsStatistics.vue?vue&type=style&index=0&id=5185895a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5185895a",
  null
  
)

export default component.exports