<template>
  <card class="minHeight">
    <template>
      <section class="my-2">
        <div class="w-100 w-md-auto p-2 d-flex flex-wrap justify-content-md-end">
          <div class="col-12 col-md-6 col-lg-4 col-xl-2 col-xxl-1">
            <Select
              class="w-100 select-primary mt-4 mt-md-0 mr-lg-4"
              :placeholder="$t('filter.year')"
              v-model="filters.year"
              @change="changeYear"
              :loading="spinners.year"
              :loading-text="$t('loading')"
            >
              <Option
                v-for="year in years"
                class="select-primary"
                :value="year"
                :label="year"
                :key="year"
              />
            </Select>
          </div>
          <div v-if="isAdmin" class="col-12 col-md-6 col-lg-4 col-xl-2 col-xxl-1">
            <Select
              class="w-100 select-primary mt-4 mt-md-0 mr-lg-4"
              :placeholder="$t('filter.store')"
              v-model="filters.store"
              @change="getChartsData()"
              :loading="spinners.store"
              :loading-text="$t('loading')"
              filterable
            >
              <Option class="select-primary" :value="null" :label="$t('allStore')" />
              <Option
                v-for="store in filtersValues.stores"
                class="select-primary"
                :value="store.id"
                :label="store.label"
                :key="store.id"
              />
            </Select>
          </div>
        </div>
      </section>
      <hr>
      <section class="d-md-flex flex-wrap gap-4 p-3 justify-content-center justify-content-lg-around">
        <div v-if="isManager" class="col-12 col-xl-10 col-xxl-6">
          <div class="mb-4">
            <h2 class="chartTitle">{{ $t('statisticsCharts.influxPerHours') }}</h2>

            <section class="chartContainer shadow-lg">
              <div class="d-flex justify-content-center align-items-center m-2">
                <base-button
                  icon
                  :disabled="isDisabledPrevArrow"
                  class="arrowIcon shadow-none"
                  @click="prevWeek"
                >
                  <img src="../../assets/img/arrow_left.svg" :alt="$t('alt.prev')" width="30rem" height="30rem">
                </base-button>
                <span class="px-5 week">
                  {{ weekInfo.start }} - {{ weekInfo.end }}
                </span>
                <base-button
                  icon
                  :disabled="isDisabledNextArrow"
                  class="arrowIcon shadow-none"
                  @click="nextWeek"
                >
                  <img src="../../assets/img/arrow_right.svg" :alt="$t('alt.next')" width="30rem" height="30rem">
                </base-button>
              </div>
              <Spinner v-if="spinners.heatMap" class="h-100"></Spinner>
              <time-heat-map-chart v-else ref="heatMap" :chartData="chartData.heatMap" :max="maxDataNumber" class="h-100" />
            </section>
          </div>
        </div>
        <div class="col-12 col-xl-10 col-xxl-6">
          <div class="mb-4">
            <h2 class="chartTitle">{{ $t('statisticsCharts.bookingsViaApp') }}</h2>
            <section>
              <Spinner v-if="spinners.bookingClients" class="h-100"></Spinner>
              <template v-else>
                <line-chart
                  v-if="chartData.bookingClients"
                  :chartData="chartData.bookingClients"
                  :chartTitle="$t('statisticsCharts.bookingsViaApp')"
                  :chartValueLabel="$tc('booking', 0)"
                  class="chartContainer shadow-lg"
                />
                <div v-else class="h-100 d-flex align-items-center justify-content-center">
                  <p>{{ $t('errorLoadingGraphic') }}</p>
                </div>
              </template>
            </section>
          </div>
        </div>
        <div class="col-12 col-xl-10 col-xxl-6">
          <section class="my-4 container-widgets">
            <div class="badge p-5 shadow">
              <div>
                <i class="icon icon-time-alarm tim-icons" />
                <span class="data d-block mt-4">{{ timeSavedInMinutes }} {{ $t('minutesAbbreviated') }}</span>
                <span class="title d-block mt-4">{{ $t('timeSaved') }}</span>
                <span class="d-block mt-1"> {{ $t('statisticsCharts.minutesPerCall') }}  </span>
              </div>
            </div>
            <div class="badge p-5 shadow">
              <div>
                <i class="icon icon-money-coins tim-icons" />
                <span class="data d-block mt-4">{{ moneySavedInHours }} €</span>
                <span class="title d-block mt-4">{{ $t('moneySaved') }}</span>
                <span class="d-block mt-1"> {{ $t('statisticsCharts.incomePerHour') }} </span>
              </div>
            </div>
            <div class="badge p-5 shadow">
              <div>
                <i class="icon icon-single-02 tim-icons" />
                <span class="data d-block mt-4">{{ newCustomers }}</span>
                <span class="title d-block mt-4">{{ $t('newCustomers') }}</span>
              </div>
            </div>
            <div class="badge p-5 shadow">
              <div>
                <i class="icon tim-icons icon-calendar-60"></i>
                <span class="data d-block mt-4">{{ totalBookings }}</span>
                <span class="title d-block mt-4">{{ $tc('booking', 0) }}</span>
              </div>
            </div>
          </section>
        </div>
      </section>
    </template>
  </card>
</template>
  
  <script>
  import { Card, LineChart, TimeHeatMapChart, Spinner } from "@/components/index";
  import { mapState } from "vuex";
  import { Select, Option } from "element-ui";
  import axios from "axios";
  import { DateTime, Settings } from 'luxon';
  
  export default {
    name: "CommercialStatistics",
    props: {
      years: { type: Array, default: () => [] }
    },
    data() {
      return {
        actualWeek: 0,
        chartData: {
          heatMap: [],
          bookingClients: null
        },
        chartOptions: {
          responsive: true,
          maintainAspectRatio: false
        },
        fullChartData: {
          heatMap: {}
        },
        weekInfo: {
          end: null,
          start: null
        },
        filters: {
          store: null,
          year: null
        },
        filtersValues: {
          stores:  null
        },
        maxDataNumber: 10,
        moneySavedInHours: 0,
        newCustomers: 0,
        totalBookings: 0,
        routes: {
          workloadStatistics: '/statistics/workload',
          bookings: '/statistics/bookings'
        },
        spinners: {
          bookingClients: false,
          heatMap: false,
          main: false
        },
        timeSavedInMinutes: 0,
      };
    },
    computed: {
      isDisabledPrevArrow(){
        return this.actualWeek <= 0;
      },
      isDisabledNextArrow(){
        return this.actualWeek >= 52;
      },
      ...mapState(["defaultTitle", "isAdmin", "isManager"]),
    },
    methods: {
      getActualWeek() {        
        const actualWeek =parseInt( DateTime.fromJSDate(new Date()).toFormat('n'));        
        this.actualWeek = actualWeek;
        this.setFormatWeek( actualWeek );
      },
      async getChartsData() {
        if(this.isManager) this.getHeatmapData();
        this.getBookingsClients();
      },
      async getBookingsClients() {
        this.spinners.bookingClients = true;
        const params = {
          year: this.filters.year,
          store_id: this.filters.store
        };

        try {
          const response = await this.axios.get(`${this.routes.bookings}`, { params } );
          
          if( response && response.data && response.data.status === 'success'){
            let totalBookings = 0;
            this.newCustomers = 0;
            
            const statistics = response.data.data.statistics;
            const data = { labels: [], colors: ['#3DADA1', '#210070'], titles: [this.$tc('booking', 0), this.$t('newCustomers')] };
            const chartsData = [
              { type: 'line', name: this.$tc('booking', 0), data: []},
              { type: 'line', name: this.$t('newCustomers'), data: []}
            ];
            const [incomeData, newCustomersData] = chartsData;

            statistics.forEach( statistic => {
              const { month, bookingCount = 0, newCustomers = 0 } = statistic;

              totalBookings += bookingCount;
              this.newCustomers += newCustomers;
              data.labels.push(this.$t(`monthsComplete.${month.toLowerCase()}`));
              incomeData.data.push(bookingCount);
              newCustomersData.data.push(newCustomers);
            });
            this.totalBookings = totalBookings; 
            this.calculateSaved(totalBookings);
            data.chartsData = chartsData;
            this.chartData.bookingClients = data;
          }
        } catch (error) {
          console.log(error);
          this.$toast.error(this.$t("notifications.error.general"));
        }
        this.spinners.bookingClients = false;
      },
      async getHeatmapData() {
        this.spinners.heatMap = true;
        const params = {};
        Object.keys(this.filters).forEach( key => {
          if(this.filters[key]) {
            params[key] = this.filters[key];
          }
        });
        
        try {
          const response = await this.axios.get(`${this.routes.workloadStatistics}`, { params } );
          
          if( response && response.data && response.data.status === 'success'){
            const statistics = response.data.data.statistics;
            const chartData = {};
            
            statistics.forEach( statistic => {
              const { week, day, hour, bookingCount } = statistic;
              
              if(!chartData[week]) chartData[week] = [];
              
              chartData[week].push([ hour, day, bookingCount ]);
            });
            
            this.fullChartData.heatMap = chartData;
            await this.getMaximumNumberData();
            await this.setHeatMapData();
          }
        } catch (error) {
          console.log(error);
          this.$toast.error(this.$t("notifications.error.general"));
        }
        this.spinners.heatMap = false;
      },
      getMaximumNumberData() {
        const weekData = this.fullChartData.heatMap[this.actualWeek];
        if(Array.isArray(weekData) && weekData.length > 0) {
          const quantities = this.fullChartData.heatMap[this.actualWeek].map( statistic => statistic[2] )
          this.maxDataNumber = Math.max( ...quantities );
        } 
      },
      async getStoresValues() {
        try {
          const params = {
            name: this.filters.store
          };

          const response = await this.axios.get('/stores/all', { params })
          const stores = response.data.data.stores;
          const values = stores.map( store => ({
            id: store.id,
            label: store.name,
            value: store.id
          }));

          this.filtersValues.stores = values;
        } catch (error) {
          console.log(error);
        }
      },
      async setHeatMapData() {
        this.spinners.heatMap = true;
        await this.getMaximumNumberData();
        this.chartData.heatMap = await this.fullChartData.heatMap[this.actualWeek] || [];
        this.spinners.heatMap = false;
      },
      setFormatWeek() {
        this.weekInfo.start = DateTime.local().set({ weekNumber: this.actualWeek, weekday: 1 }).toFormat("dd/LL/yyyy");
        this.weekInfo.end = DateTime.local().set({ weekNumber: this.actualWeek, weekday: 7 }).toFormat("dd/LL/yyyy");
        this.setHeatMapData();
      },
      calculateSaved(totalBookings) {
        this.timeSavedInMinutes = Math.ceil((totalBookings * 1.5));
        this.moneySavedInHours = ( this.timeSavedInMinutes / 60 * 20 ).toLocaleString('es-ES', { maximumFractionDigits: 2 });
      },
      async changeYear(year) {
        this.filters.year = year;

        try {
          await this.getChartsData();
          this.actualWeek = 0;
          this.setFormatWeek();
        } catch (err) { console.log(err); }
      },
      prevWeek() {
        this.actualWeek--;
        this.setFormatWeek();
      },
      nextWeek() {
        this.actualWeek++;
        this.setFormatWeek();
      }
    },
    async mounted() {
      this.filters.year = new Date().getFullYear();
      if(this.isAdmin) {
        await this.getStoresValues();
      } 
      await this.getActualWeek();
      await this.getChartsData();
    },
    metaInfo() {
      return { title: `${this.$t('inflowStatistics')} - ${this.defaultTitle}` }
    },
    components: {
      Card,
      LineChart,
      TimeHeatMapChart,
      Option,
      Select,
      Spinner
    },
  };
</script>
  
<style scoped lang="scss">
  @import '../../assets/scss/white-dashboard.scss';
  .minHeight {
    min-height: 90vh !important;
  }
  .chartContainer {
    padding: 2rem;
    border-radius:0.4285rem;
    border: 1px solid rgba(0,0,0,.1);
    height: 700px;
  }
  .chartTitle{
    font-size: 1rem;
    font-weight: normal;
    margin: 1rem 0;
  }
  .arrowIcon {
    cursor: pointer;
  }
  .arrowIcon:hover {
    background: none!important;
  }
  .arrowIcon:active, .arrowIcon:focus {
    background: none!important;
    border: none
  }
  .week, .month {
    font-size: 1rem;
    width: 20rem;
    text-align: center;
  }
  .badge {
    border-radius: 0.4285rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      font-size: 40px;
    }
  }
  .title {
    font-size: 0.8rem;
  }
  .data {
    font-size: 1.2rem;
    color: $primary;
  }

  .container-widgets {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  }
</style>