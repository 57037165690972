<template>
    <div class="content">
      <tabs>
        <tab v-if="isManager || isAdmin" :name="$tc('commercial', 2)" :selected="tabSelected === 'commercial'">
          <commercial-statistics
            :employees="employees"
            :services="services"
            :years="years"
            :stores="stores"
          />
        </tab>
        <tab :name="$t('affluence')" :selected="tabSelected === 'affluence'">
          <heat-map-statistics :years="years"/>
        </tab> 
        <tab v-if="isManager" :name="$t('dailyOperations')" :selected="tabSelected === 'dailyOperations'">
          <daily-operations-statistics
            :employees="employees"
            :services="services"
            :months="months"
            :years="years"
          />
        </tab> 
      </tabs>
    </div>
  </template>
  
<script>
  import { Tabs, Tab } from "../../components";
  import HeatMapStatistics from './HeatMapStatistics.vue';
  import CommercialStatistics from './CommercialStatistics.vue';
  import DailyOperationsStatistics from './DailyOperationsStatistics.vue';
  import { mapState } from "vuex";
  
  export default {
    name: "StatisticsManagement",
    computed: {
      ...mapState(["isManager", "isAdmin"]),
    },
    data() {
      return {
        tabSelected: 'commercial',
        services: null,
        employees: null,
        months: null,
        stores: null,
        years: null,
        routes: {
          employees: '/employees',
          services: '/service',
          stores: '/stores'
        }
      }
    },
    methods: {
      async getEmployees() {
        try {
          const response = await this.axios.get(`${this.routes.employees}/all`);

          if(response && response.data && response.data.status === "success") {
            this.employees = response.data.data.employees;
          }
        } catch (error) {
          this.$toast.error(this.$t("notifications.error.general"));
        }
      },
      async getServices() {
        try {
          const response = await this.axios.get(`${this.routes.services}/all`);
          
          if(response && response.data && response.data.status === "success") {
            this.services = response.data.data.services;
          }
        } catch (error) {
          this.$toast.error(this.$t("notifications.error.general"));
        }
      },
      async getStores() {
        try {
          const response = await this.axios.get(`${this.routes.stores}/all`);
          
          if(response && response.data && response.data.status === "success") {
            this.stores = response.data.data.stores;
          }
        } catch (error) {
          this.$toast.error(this.$t("notifications.error.general"));
        }
      },
      getMonthsValues() {
        const maxMonth = 12;
        const months = [];

        for (let i = 1; i <= maxMonth; i++) {
          months.push({ value: i, key: this.$t(`months[${i}]`) });
        }

        this.months = months;
      },
      getYearsValues() {
        const minYear = 2022;
        const actualYear = new Date().getFullYear();
        const years = [];
        
        for (let year = minYear; year <= actualYear; year++) {
          years.push( year );
        }

        this.years = years;
      }
    },
    async beforeMount() {
      if(this.isManager) {
        await this.getEmployees();
        await this.getServices();
      }

      if(this.isAdmin) {
        await this.getStores();
      }
      
      await this.getMonthsValues();
      await this.getYearsValues();
    },
    metaInfo() {
      return { title: `${this.$t('commercialStatistics', 0)} - ${this.defaultTitle}` }
    },
    components: {
      CommercialStatistics,
      DailyOperationsStatistics,
      HeatMapStatistics,
      Tab,
      Tabs,
    },
  };
  </script>