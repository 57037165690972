<template>
    <card>
      <template>
        <section class="my-2">
          <div class="w-100 w-md-auto p-2 d-flex flex-wrap justify-content-md-end">
            <div class="col-12 col-md-6 col-lg-4 col-xl-2 col-xxl-1">
              <Select
                class="w-100 select-primary mb-4 mb-lg-0 mr-lg-4"
                :placeholder="$t('filter.employee')"
                v-model="filters.employee"
                @change="getTableData()"
              >
                <Option class="select-primary" :value="''" :label="$t('allEmployee')" @change="filters.employee = null"/>
                <Option
                  v-for="employee in employees"
                  class="select-primary"
                  :value="employee.id"
                  :label="employee.name"
                  :key="employee.id"
                />
              </Select>
            </div>

            <div class="col-12 col-md-6 col-lg-4 col-xl-2 col-xxl-1">
              <Select
                class="w-100 select-primary"
                :placeholder="$t('filter.service')"
                v-model="filters.service"
                @change="getTableData()"
              >
                <Option class="select-primary" :value="''" :label="$t('allService')" @change="filters.service = null"/>
                <Option
                  v-for="service in services"
                  class="select-primary"
                  :value="service.id"
                  :label="service.name"
                  :key="service.id"
                />
              </Select>
            </div>
            
            <div class="col-12 col-md-6 col-lg-4 col-xl-2 col-xxl-1">
              <Select
                class="w-100 select-primary mt-4 mt-md-0 mr-lg-4"
                :placeholder="$t('filter.month')"
                v-model="filters.month"
                @change="getTableData()"
              >
                <Option
                v-for="month in monthsFiltered"
                class="select-primary"
                :value="month.value"
                :label="month.key"
                :key="month.key"
                />
              </Select>
            </div>
            
            <div class="col-12 col-md-6 col-lg-4 col-xl-2 col-xxl-1">
              <Select
                class="w-100 select-primary mt-4 mt-md-0 mr-lg-4"
                :placeholder="$t('filter.year')"
                v-model="filters.year"
                @change="filterMonths();getTableData()"
              >
                <Option
                v-for="year in years"
                class="select-primary"
                :value="year"
                :label="year"
                :key="year"
                />
              </Select>
            </div>
          </div>
        </section>

        <hr>
  
        <section class="p-3 container-table">
          <h2 class="title">{{ $t('dailyRevenuesAndBookings') }}</h2>
          <div v-if="spinners.table" class="spinner-container">
              <Spinner classes="text-info" />
          </div>
          <base-table
            v-else
            :data="bookingsAndIncomes"
            class="table-responsive-xs daily-revenues-bookings-table"
            aria-describedby="description"
          >
            <template slot="columns">
              <th scope="col">{{ $tc("day", 1) }}</th>
              <th scope="col">{{ $tc("booking", 0) }}</th>
              <th scope="col">{{ $tc("income", 0) }}</th>
            </template>
            <template slot-scope="{ row }">
              <td :class="{ 'total-column' : !row.day }">{{ formatDateToColumnDay(row.day) }}</td>
              <td :class="{ 'total-column' : !row.day }">{{ row.bookingCount }} {{ $tc('booking', row.bookingCount === 1? 1 : 0 ) }}</td>
              <td :class="{ 'total-column' : !row.day }">{{ row.income }}€</td>
            </template>
          </base-table>
        </section>
      </template>
    </card>
  </template>
    
  <script>
    import { BaseTable, Card, Spinner } from "@/components/index";
    import { mapState } from "vuex";
    import { Select, Option } from "element-ui";
    import { DateTime, Settings } from 'luxon';
    export default {
      name: "DailyOperationsStatistics",
      props: {
        employees: { type: Array, default: () => [] },
        services: { type: Array, default: () => [] },
        months: { type: Array, default: () => [] },
        years: { type: Array, default: () => [] }
      },
      data() {
        return {
          bookingsAndIncomes: [],
          monthsFiltered: [],
          filters: {
            employee: null,
            month: null,
            service: null,
            year: null
          },
          routes: {
            incomeStatistics: '/statistics/income',
          },
          spinners: {
            table: false,
          },
        };
      },
      computed: {
        ...mapState(["defaultTitle"]),
      },
      methods: {
        async getTableData() {
          this.spinners.table = true;
          const params = {};

          Object.keys(this.filters).forEach( key => {
            if(this.filters[key]) {
              params[key] = this.filters[key];
            }
          });
  
          try {
            const response = await this.axios.get(`${this.routes.incomeStatistics}`, { params });
            
            if(response?.data?.status === "success") {
              const statistics = response.data.data.statistics;
              const totalStatisticsObj = this.getTotalStatistics([...statistics]);
              
              this.bookingsAndIncomes = [...statistics, totalStatisticsObj];
            }
          } catch (error) {
              this.$toast.error(this.$t("notifications.error.general"));
              console.log(error);
          } finally {
              this.spinners.table = false;
          }
        },
        getTotalStatistics(statistics) {
          let totalBookings = 0;
          let totalIncomes = 0;

          statistics.forEach(({ income, bookingCount }) => {
            totalBookings += bookingCount;
            totalIncomes += income;
          })

          return { bookingCount: totalBookings, income: totalIncomes };
        },
        formatDateToColumnDay(day) {
          if(!day) return this.$t('total');
          const date = new Date(`${this.filters.year}-${this.filters.month}-${day}`);          
          const dateFormat = DateTime.fromJSDate(new Date(date)).toFormat(`cccc dd '${this.$t('from').toLowerCase()}' LLLL `);
          return `${dateFormat[0].toUpperCase()}${dateFormat.slice(1)}`;
        },
        filterMonths() {
          if(!this.months) return;

          const date = new Date();
          const yearActual = date.getFullYear();

          if(yearActual === this.filters.year) {
            const monthActual = date.getMonth() + 1;

            this.monthsFiltered = this.months.slice(0, monthActual);
            return;
          }

          this.monthsFiltered = [...this.months];
        }
      },
      async mounted() {
        const date = new Date();
        this.filters.month = date.getMonth() + 1;
        this.filters.year = date.getFullYear();

        this.getTableData();
      },
      watch: {
        months() {
          this.filterMonths();
        }
      },
      metaInfo() {
        return { title: `${this.$tc('statistics', 0)} - ${this.defaultTitle}` }
      },
      components: {
        BaseTable,
        Card,
        Option,
        Select,
        Spinner
      },
    };
</script>
    
<style scoped lang="scss">
.title{
  font-size: 1rem;
  font-weight: normal;
  margin: 1rem 0;
}
.container-table {
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}
.total-column {
  font-weight: 600;
  background:#eeeeee75;
  cursor: default;
}
.spinner-container {
  min-height: 400px;
  display: flex;
  justify-content: center;
}

@media (min-width: 768px) {
  .container-table {
    max-width: 1000px;
    margin: 0 auto;
  }
}
</style>