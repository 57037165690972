<template>
  <card>
    <template>
      <section class="my-2">
        <div class="w-100 w-md-auto p-2 d-flex flex-wrap justify-content-md-end">
          <div v-if="isManager" class="col-12 col-md-6 col-lg-4 col-xl-2 col-xxl-1">
            <Select
              class="w-100 select-primary mb-4 mb-lg-0 mr-lg-4"
              :placeholder="$t('filter.employee')"
              v-model="filters.employee"
              @change="getChartsData()"
            >
              <Option class="select-primary" :value="''" :label="$t('allEmployee')" @change="filters.employee = null"/>
              <Option
                v-for="employee in employees"
                class="select-primary"
                :value="employee.id"
                :label="employee.name"
                :key="employee.id"
              />
            </Select>
          </div>

          <div v-if="isManager" class="col-12 col-md-6 col-lg-4 col-xl-2 col-xxl-1">
            <Select
              class="w-100 select-primary"
              :placeholder="$t('filter.service')"
              v-model="filters.service"
              @change="getChartsData()"
            >
              <Option class="select-primary" :value="''" :label="$t('allService')" @change="filters.service = null"/>
              <Option
                v-for="service in services"
                class="select-primary"
                :value="service.id"
                :label="service.name"
                :key="service.id"
              />
            </Select>
          </div>

          <div v-if="isAdmin" class="col-12 col-md-6 col-lg-4 col-xl-2 col-xxl-1">
            <Select
              class="w-100 select-primary"
              :placeholder="$t('filter.store')"
              v-model="filters.store_id"
              @change="getChartsData()"
            >
              <Option class="select-primary" :value="''" :label="$t('allStore')" @change="filters.store_id = null"/>
              <Option
                v-for="store in stores"
                class="select-primary"
                :value="store.id"
                :label="store.name"
                :key="store.id"
              />
            </Select>
          </div>

          <div class="col-12 col-md-6 col-lg-4 col-xl-2 col-xxl-1">
            <Select
              class="w-100 select-primary mt-4 mt-md-0 mr-lg-4"
              :placeholder="$t('filter.year')"
              v-model="filters.year"
              @change="getChartsData()"
            >
              <Option
                v-for="year in years"
                class="select-primary"
                :value="year"
                :label="year"
                :key="year"
              />
            </Select>
          </div>
        </div>
      </section>
      <hr>

      <section class="d-md-flex flex-wrap gap-4 p-3 justify-content-center justify-content-lg-around my-3">
        <div class="col-12 col-lg-10 col-xl-8 col-xxl-6 mb-5">
          <h2 class="chartTitle">{{ $t('incomes.month') }}</h2>
          <mixed-chart
            v-if="charts.incomeAnnual.chartData"
            :chartData="charts.incomeAnnual.chartData"
            :chartOptions="chartOptions"
            class="chartContainer shadow-lg"
          />
          <div v-else class="chartLoading chartContainer shadow-lg">
            <Spinner />
          </div>
        </div>
        <div v-if="isManager" class="col-12 col-lg-10 col-xl-8 col-xxl-6">
          <h2 class="chartTitle">{{ $t('incomes.week') }}</h2>
          <mixed-chart
            v-if="charts.incomeWeekly.chartData"
            :chartData="charts.incomeWeekly.chartData"
            :chartOptions="chartOptions"
            class="chartContainer shadow-lg"
          />
          <div v-else class="chartLoading chartContainer shadow-lg">
            <Spinner />
          </div>
        </div>
      </section>
    </template>
  </card>
</template>
  
<script>
  import { Card, MixedChart, Spinner } from "@/components/index";
  import { mapState } from "vuex";
  import { Select, Option } from "element-ui";
  
  export default {
    name: "CommercialStatistics",
    props: {
      employees: { type: Array, default: () => [] },
      services: { type: Array, default: () => [] },
      years: { type: Array, default: () => [] },
      stores: { type: Array, default: () => [] }
    },
    data() {
      return {
        charts: {
          incomeAnnual: { chartData: null },
          incomeWeekly: { chartData: null }
        },
        chartOptions: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            tooltip: {
              enabled: true,
              callbacks: {
                label: ( tooltipItems => {
                  if(tooltipItems.dataset.type === 'line') {
                    return `${this.$tc('booking', 0)} ${tooltipItems.raw}`;
                  }
                  return `${this.$tc('income', 0)} ${tooltipItems.raw}€`;
                })
              }
            },
          },
          scales: {
            left: {
              type: 'linear',
              position: 'left',
              ticks: { beginAtZero: true, color: '#00BF9A' },
              // Hide grid lines, otherwise you have separate grid lines for the 2 y axes
              grid: { display: true }
            },
            right: {
              type: 'linear',
              position: 'right',
              ticks: { beginAtZero: true, color: '#210070' },
              grid: { display: true }
            },
            x: { ticks: { beginAtZero: true } }
          }
        },
        filters: {
          employee: null,
          service: null,
          year: null,
          store_id: null
        },
        routes: {
          incomeStatistics: '/statistics/income',
        },
        spinners: {
          employees: false,
          services: false,
          year: false,
          store: false
        }
      };
    },
    computed: {
      ...mapState(["defaultTitle", "isManager", "isAdmin"]),
    },
    methods: {
      setChartData(chart, payload) {
        const chartData = {
          labels: [],
          datasets: [
            {
              label: this.$tc('income', '0'),
              data: []
            },
            {
              label: this.$tc('booking', '0'),
              data: [],
            }
          ]
        };

        payload.forEach( statistic => {
          if(chart === 'incomeAnnual') {
            chartData.labels =  [ ...chartData.labels, this.$t(`monthsComplete.${statistic.month.toLowerCase()}`) ];
          } else {
            chartData.labels =  [ ...chartData.labels, `${this.$tc('week', 1)} ${statistic.week}`];
          }

          chartData.datasets[0].data = [ ...chartData.datasets[0].data, this.fixToTwoDecimalsInString(statistic.income) ];
          chartData.datasets[1].data = [ ...chartData.datasets[1].data, this.fixToTwoDecimalsInString(statistic.bookingCount)];
        })

        this.charts[chart].chartData = chartData;
      },
      async getIncomeData(type = 'incomeAnnual') {
        const params = {};
        Object.keys(this.filters).forEach( key => {
          if(this.filters[key]) {
            params[key] = this.filters[key];
          }
        });

        if(type === 'incomeWeekly') params.weekly = true;

        try {
          const response = await this.axios.get(`${this.routes.incomeStatistics}`, { params });
          
          if(response && response.data && response.data.status === "success") {
            const statistics = response.data.data.statistics;
            this.setChartData(type, statistics);
          }
        } catch (error) {
          this.$toast.error(this.$t("notifications.error.general"));
        }
      },
      async getChartsData() {
        const charts = ['incomeAnnual'];
        if(this.isManager) charts.push('incomeWeekly');

        charts.forEach( chart => {
          this.charts[chart].chartData = null;
           this.getIncomeData(chart) 
        });
      },
      fixToTwoDecimalsInString(value) {
        const valueNumberTwoDecimals = parseFloat(Number(value).toFixed(2));
        return String( valueNumberTwoDecimals );
      }
    },
    async mounted() {
      this.filters.year = new Date().getFullYear();

      this.getChartsData();
    },
    metaInfo() {
      return { title: `${this.$tc('statistics', 0)} - ${this.defaultTitle}` }
    },
    components: {
      Card,
      MixedChart,
      Option,
      Select,
      Spinner
    },
  };
</script>
  
<style scoped lang="scss">
  .minHeight {
    min-height: 90vh !important;
  }
  .chartContainer{
    padding: 2rem;
    border-radius:0.4285rem;
    border: 1px solid rgba(0,0,0,.1);
  }
  .chartLoading {
    padding: 2rem;
    border-radius:0.4285rem;
    border: 1px solid rgba(0,0,0,.1);
    height: 35rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .chartTitle{
    font-size: 1rem;
    font-weight: normal;
    margin: 1rem 0;
  }
  .arrowIcon {
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
</style>